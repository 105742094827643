import Component from "gia/Component";
import eventbus from "gia/eventbus";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class productInfo extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      orderButton: null,
    };
    //////
    this.options = {
      title: "defaultValue",
      slug: "defaultValue",
      price: "defaultValue",
      format: "defaultValue",
      additionalInfo: "defaultValue",
      description_formatted_noLineBreaks: "defaultValue",
      thumbnailURL: "defaultValue",
    };
    //////
    this.consoleStyle_def = "color: lime; background: black";
    this.consoleStyle_def_red = "color: red; background: black";
  }

  mount() {
    const compEl = this.element;
    const orderButton = this.ref.orderButton;

    const productTitle = this.options.title;
    const productSlug = this.options.slug;
    const productPrice = this.options.price;
    const productFormat = this.options.format;
    const productAdditionalInfo = this.options.additionalInfo;
    const productDescription = this.options.description_formatted_noLineBreaks;
    const thumbnailURL = this.options.thumbnailURL;

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    orderButton.addEventListener("click", () => {
      ///////////////////////////////////////////////////////////////////
      // Check if item exists in local-storage                         //
      // (to determine if to add new or increase quantity of existing) //
      ///////////////////////////////////////////////////////////////////

      let currentItems = JSON.parse(localStorage.getItem("items"));
      let addNewToCart = false;

      if (currentItems.length === 0) {
        addNewToCart = true;
      } else {
        for (let item of currentItems) {
          const itemSlug = item.slug;

          //////
          if (itemSlug === productSlug) {
            const quantity_current = parseInt(item.quantity, 10);
            const quantity_new = quantity_current + 1;
            item.quantity = quantity_new;
            //////
            addNewToCart = false;
            break;
          } else {
            addNewToCart = true;
          }
        }
      }

      //////////////////
      // Add new item //
      //////////////////

      if (addNewToCart === true) {
        const newItem_PROTO = {
          slug: productSlug,
          quantity: 1,
          title: productTitle,
          price: productPrice,
          format: productFormat,
          additionalInfo: productAdditionalInfo,
          productDescription: productDescription,
          thumbnailURL: thumbnailURL,
        };
        currentItems.push(newItem_PROTO);
      }

      localStorage.setItem("items", JSON.stringify(currentItems));
      eventbus.emit("change_cart");
    });
  }
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { productInfo };
