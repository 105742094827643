import Component from "gia/Component";
import eventbus from "gia/eventbus";
import Plyr from "plyr";
//
class projectVideoPlayer extends Component {
  constructor(element) {
    super(element);
    //
    this.ref = {
      iframeWrapper: null,
      playBtn: null,
    };
    //
    this.lstnr_call_slideChange = this.hdl_call_slideChange.bind(this);
  }
  //
  mount() {
    const componentEl = this.element;
    const iframeWrapper = this.ref.iframeWrapper;
    const playBtn = this.ref.playBtn;

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    this.setState({
      player: {},
      playback: "ended",
      playbackTime: 0,
      //
      duration: 0,
    });

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    const plyrInst = new Plyr(iframeWrapper, {
      controls: false,
    });

    this.setState({
      player: { plyrInst },
    });

    plyrInst.on("play", () => {
      componentEl.classList.add("playing");
    });
    plyrInst.on("pause", () => {
      componentEl.classList.remove("playing");
    });
    plyrInst.on("ended", () => {
      componentEl.classList.remove("playing");
      //
      this.setState({
        playback: "ended",
      });
    });
    plyrInst.on("timeupdate", () => {
      this.setState({
        playbackTime: plyrInst.currentTime,
      });
    });

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    playBtn.addEventListener("click", () => {
      this.setState({
        playback: "playing",
      });
    });

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    eventbus.on("projectSlider_slideChange", this.lstnr_call_slideChange);
  }
  //
  unmount() {
    eventbus.off("projectSlider_slideChange", this.lstnr_call_slideChange);
  }
  //
  hdl_call_slideChange() {
    this.setState({
      playback: "ended",
    });
  }
  //
  stateChange(changes) {
    if ("playback" in changes) {
      const player = this.state.player;
      const playback = changes.playback;
      //
      if ((Object.keys(player).length === 0 && player.constructor === Object) === false) {
        if (playback === "playing") {
          player.plyrInst.play();
        } else {
          player.plyrInst.pause();
        }
      }
    }

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    if ("playbackTime" in changes) {
      const player = this.state.player;
      //
      if ((Object.keys(player).length === 0 && player.constructor === Object) === false) {
        const duration = player.plyrInst.duration;
        const playbackTime = changes.playbackTime;
        const percentPlayed = playbackTime / (duration / 100);
        //
        const args = { percentPlayed: percentPlayed };
        eventbus.emit("update_playerProgress", args);
      }
    }
  }
}
class playerProgress extends Component {
  constructor(element) {
    super(element);
    //
    this.lstnr_update_playerProgress = this.hdl_update_playerProgress.bind(this);
  }
  //
  mount() {
    const componentEl = this.element;

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    eventbus.on("update_playerProgress", this.lstnr_update_playerProgress);
  }
  //
  hdl_update_playerProgress(args) {
    const componentEl = this.element;
    const percentPlayed = args.percentPlayed;
    //
    componentEl.style.width = percentPlayed + "%";
  }
  //
  unmount() {}
}
//
const components_projectVideoPlayer = {
  projectVideoPlayer: projectVideoPlayer,
  playerProgress: playerProgress,
};

export default components_projectVideoPlayer;
