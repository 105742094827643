import Component from "gia/Component";
import eventbus from "gia/eventbus";

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

class nav extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      closeBtn: null,
      portrait: null,
      links: [],
    };
  }

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    const navEl = this.element;
    const closeBtn = this.ref.closeBtn;
    const portrait = this.ref.portrait;
    const links = this.ref.links;

    ////////////////////////////////////
    ////////////////////////////////////

    this.setState({
      scrollPosition_current: 0,
      scrollPosition_grid: 0,
    });

    window.addEventListener("scroll", this.hdl_windowScroll.bind(this));

    eventbus.on("swup_leaving_homepage", () => {
      const scrollPosition_current = this.state.scrollPosition_current;
      this.setState({
        scrollPosition_grid: scrollPosition_current,
      });
    });
    eventbus.on("swup_entered_homepage", () => {
      const scrollPosition_grid_last = this.state.scrollPosition_grid;
      document.documentElement.scrollTop = scrollPosition_grid_last;
    });

    eventbus.on("open_cart", () => {
      navEl.classList.add("hidden");
    });
    eventbus.on("close_cart", () => {
      navEl.classList.remove("hidden");
    });

    ////////////////////////////////////
    ////////////////////////////////////

    closeBtn.addEventListener("click", () => {
      document.body.classList.toggle("has-open-nav");
      navEl.classList.toggle("closed");
      eventbus.emit("open/close_nav");
    });

    ////////////////////////////////////
    ////////////////////////////////////

    portrait.addEventListener("click", () => {
      document.body.classList.toggle("has-open-nav");
      navEl.classList.toggle("closed");
      eventbus.emit("open/close_nav");
    });

    ////////////////////////////////////
    ////////////////////////////////////

    for (let i = 0; i < links.length; i++) {
      const link = links[i];
      const target = link.dataset.target;
      //////
      link.addEventListener("click", () => {
        for (let link of links) {
          link.classList.remove("active");
        }
        //
        link.classList.add("active");
        //
        document.body.classList.toggle("has-open-nav");
        navEl.classList.add("closed");
        eventbus.emit("open/close_nav");
      });

      ///////////////////////////////////////////////
      // Adding namespace-specific event-listeners //
      ///////////////////////////////////////////////

      if (target === "shop") {
        link.addEventListener("click", () => {
          eventbus.emit("open_shop");
        });
      }

      if (target != "shop") {
        link.addEventListener("click", () => {
          eventbus.emit("close_shop");
        });
      }
    }
  }

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  hdl_windowScroll() {
    const scrollPosition_new = document.documentElement.scrollTop;
    this.setState({
      scrollPosition_current: scrollPosition_new,
    });
  }
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

class bottomNav extends Component {
  constructor(element) {
    super(element);
  }
  //
  mount() {
    const bottomNavEl = this.element;

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    eventbus.on("open/close_nav", () => {
      bottomNavEl.classList.toggle("closed");
    });
  }
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

const components_nav = {
  nav: nav,
  bottomNav: bottomNav,
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

export default components_nav;
