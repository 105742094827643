import Component from "gia/Component";
import eventbus from "gia/eventbus";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class orderForm extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      closeButton: null,
      sendOrderButton: null,
      //////
      quantityInput: null,
      totalPrice: null,
      //////
      nameInput: null,
      emailInput: null,
      addressInput: null,
      notesInput: null,
      //////
      submissionMessage: null,
    };
    //////
    this.options = {
      productPrice: "defaultValue",
    };
  }

  mount() {
    const closeButton = this.ref.closeButton;
    const sendOrderButton = this.ref.sendOrderButton;

    const productPrice = this.options.productPrice;
    const quantityInput = this.ref.quantityInput;
    const totalPrice = this.ref.totalPrice;

    const nameInput = this.ref.nameInput;
    const emailInput = this.ref.emailInput;
    const addressInput = this.ref.addressInput;
    const notesInput = this.ref.notesInput;

    const submissionMessage = this.ref.submissionMessage;

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    quantityInput.addEventListener("change", () => {
      const quantity = quantityInput.value;
      const newPrice = productPrice * quantity;
      const newPrice_formatted = (Math.round(newPrice * 100) / 100).toFixed(2);
      //////
      totalPrice.value = newPrice_formatted + " €";
    });

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    closeButton.addEventListener("click", () => {
      eventbus.emit("close_orderForm");
    });

    sendOrderButton.addEventListener("click", () => {
      const name = nameInput.value;
      const email = emailInput.value;
      const address = addressInput.value;
      const notes = notesInput.value;
      //////
      Email.send({
        Host: "smtp.elasticemail.com",
        Username: "johannes@schmoll.studio",
        Password: "1B5C6933256231A202630BAC67FA60399C73",
        To: "j.schmoll@udk-berlin.de",
        From: "johannes@schmoll.studio",
        Subject: "Neue Bestellung",
        Body:
          "<html>" +
          "<p>" +
          "Eine neue Bestellung wurde aufgegben </br></br>" +
          "Name: </br>" +
          name +
          "</br></br>" +
          "E-Mail-Adresse: </br>" +
          email +
          "</br></br>" +
          "Lieferadresse: </br>" +
          address +
          "</br></br>" +
          "Anmerkungen: </br>" +
          notes +
          "</p>" +
          "</html>",
      }).then((message) => {
        nameInput.value = "";
        emailInput.value = "";
        addressInput.value = "";
        notesInput.value = "";
        //////
        submissionMessage.classList.remove("order-form__art_hidden");
      });
    });
  }
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
export { orderForm };
