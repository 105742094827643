import Component from "gia/Component";
//
class backToTopBtn extends Component {
  constructor(element) {
    super(element);
  }
  //
  mount() {
    const compEl = this.element;

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    compEl.addEventListener("click", () => {
      document.documentElement.scrollTop = 0;
    });
  }
  unmount() {}
}
//
const components_backToTopBtn = {
  backToTopBtn: backToTopBtn,
};
//
export default components_backToTopBtn;
