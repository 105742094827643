import Component from "gia/Component";
import eventbus from "gia/eventbus";
import loadComponents from "gia/loadComponents";

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

import { cartItem } from "../cart-item/cartItem";

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

// ...

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

class cart extends Component {
  constructor(element) {
    super(element);
    this.ref = {
      main: null,
      closeButton: null,
      itemWrapper: null,
      totalQuantity: null,
      totalPrice: null,
    };
  }

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    const ref = this.ref;

    const main = ref.main;
    const itemWrapper = ref.itemWrapper;
    const closeButton = ref.closeButton;

    ////////////////////////////////////
    ////////////////////////////////////

    this.setState({
      totalPrice: 0,
      totalQuantity: 0,
    });

    ////////////////////////////////////
    ////////////////////////////////////

    ///////////////////////
    // Render cart items //
    ///////////////////////

    const cartItems = JSON.parse(localStorage.getItem("items"));

    if (cartItems.length > 0) {
      this.setState({
        active: true,
      });
      //////
      for (let item of cartItems) {
        const productTitle = item.title;
        const productSlug = item.slug;
        const productPrice = item.price;
        const productFormat = item.format;
        const productAdditionalInfo = item.additionalInfo;
        const productDescription = item.description_formatted_noLineBreaks;
        const thumbnailURL = item.thumbnailURL;

        const quantity = item.quantity;
        const is__single = quantity === "1" || quantity === 1;
        const total = (productPrice * quantity).toFixed(2);

        const lang = document.body.dataset.lang;

        const cartItem_PROTO = `
          <div class="cart-item"
               g-component="cartItem"
               g-options='{ 
                 "slug"     : "${productSlug}", 
                 "price"    : "${productPrice}", 
                 "quantity" : "${quantity}" 
                }'>
          <div class="cart-item__image">
            <img src="${thumbnailURL}">
          </div>
  
          <div class="cart-item__meta">
            <div class="cart-item__meta-content">
              <div class="cart-item__info">
                <p class="cart-item__title">${productTitle}</p>
                <p class="cart-item__details">
                  ${productFormat} ${productAdditionalInfo.length > 0 ? ", " + productAdditionalInfo : ""}
                </p>
              </div>
  
              <div class="cart-item__quantity">
                <div class="cart-item__quantity-button-group">
                  <button class="cart-item__quantity-button
                                 cart-item__quantity-button_up"
                          g-ref="increaseQuantityButton"></button>
                  <div class="cart-item__quantity-button-divider"></div>
                  <button class="cart-item__quantity-button
                                 cart-item__quantity-button_down
                                 ${is__single ? "cart-item__quantity-button_inactive" : ""}"
                          g-ref="decreaseQuantityButton"></button>
                </div>
                <div class="cart-item__quantity-display">
                  <span class="cart-item__multiplication-sign">×</span>
                  <span class="cart-item__quantity-value"
                               g-ref="quantityValue">${quantity}</span>
                </div>
              </div>
              <div class="cart-item__price">
                <span g-ref="priceValue"></span>
              </div>
            </div>
          </div>
  
          <button class="cart-item__delete-button button button_small" g-ref="deleteButton">
            ${lang === "de" ? "löschen" : "delete"}
          </button>
        </div>`;

        itemWrapper.insertAdjacentHTML("beforeend", cartItem_PROTO);
      }
    } else {
      this.setState({
        active: false,
      });
    }

    ///////////////////////////////
    // Load cart-item components //
    ///////////////////////////////

    loadComponents({ cartItem: cartItem }, itemWrapper);

    //////////////////////////
    // Config. interactions //
    //////////////////////////

    closeButton.addEventListener("click", () => {
      eventbus.emit("close_cart");
    });

    //////////////////////
    // Config. eventbus //
    //////////////////////

    eventbus.on("change_cart", () => {
      /////////////////////////////////////////////////
      // Add up total price & quantity               //
      // (get quantity & price of all current items) //
      /////////////////////////////////////////////////

      let currentItems = JSON.parse(localStorage.getItem("items"));
      let totalPrice_new = 0;
      let totalQuantity_new = 0;

      for (let item of currentItems) {
        const quantity = parseFloat(item.quantity);
        const price = parseFloat(item.price);
        const total = price * quantity;
        totalPrice_new = totalPrice_new + total;
        totalQuantity_new = totalQuantity_new + quantity;
      }

      this.setState({
        totalPrice: totalPrice_new.toFixed(2).replace(".", ","),
        totalQuantity: totalQuantity_new,
      });
    });
  }

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(changes) {
    const el = this.element;
    const ref = this.ref;
    const state = this.state;

    ////////////////////////////////////
    ////////////////////////////////////

    if ("active" in changes) {
      const active = state.active;
      if (active) {
        el.classList.remove("cart_inactive");
      } else {
        el.classList.add("cart_inactive");
      }
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("totalPrice" in changes) {
      const totalPriceEl = ref.totalPrice;
      const totalPrice_current = state.totalPrice;
      //////
      totalPriceEl.innerHTML = totalPrice_current + " €";
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("totalQuantity" in changes) {
      const totalQuantity_current = state.totalQuantity;
      const totalQuantityEl = ref.totalQuantity;
      //////
      totalQuantityEl.innerHTML = totalQuantity_current;
      //////
      if (totalQuantity_current > 0) {
        this.setState({
          active: true,
        });
      } else {
        this.setState({
          active: false,
        });
      }
    }
  }
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

export { cart };
