import Component from "gia/Component";
import eventbus from "gia/eventbus";

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

class orderForm_PROTO extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      closeButton: null,
      summary: null,
      totalPrice: null,
      form: null,
      sendOrderButton: null,
      sendOrderButton_remote: null,
      //////
      message_submission: null,
    };
  }

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    const ref = this.ref;

    //////////////////
    // Init. states //
    //////////////////

    this.setState({
      submitted: false,
      URL_exit__slug: "/cart",
    });

    ////////////////////
    // Render summary //
    ////////////////////

    const summary = ref.summary;
    const items = JSON.parse(localStorage.getItem("items"));

    const markup_item = (item) => {
      const format = item.format;
      const quantity = item.quantity;
      const price_single = item.price;
      const price_total = (price_single * quantity).toFixed(2).replace(".", ",");
      //////
      return `
        <div class="order-form__item">

          <div class="order-form__item-row">

            <div class="order-form__item-title">
            <span>${item.title}</span>
            </div>

            <div class="order-form__item-meta">
              <div class="order-form__item-quantity
                          cart-item__quantity-display">
                <span class="cart-item__multiplication-sign">×</span>
                <span class="cart-item__quantity-value">${quantity}</span>
              </div>
              <div class="order-form__item-price">
                <span g-ref="priceValue">${price_total} €</span>
              </div>
            </div>
          </div>

          <div class="order-form__item-row">
            <div class="order-form__item-meta">
              <span>${format}</span>
            </div>  
          </div>

        </div>
      `;
    };

    if (items.length > 0) {
      for (let item of items) {
        summary.insertAdjacentHTML("afterbegin", markup_item(item));
      }
    }

    ///////////////
    // Set total //
    ///////////////

    const totalPrice = ref.totalPrice;
    const totalPrice__value = (Items) => {
      let value = 0;
      for (let Item of Items) {
        value = value + parseFloat(Item.price) * Item.quantity;
      }
      return value;
    };
    totalPrice.innerHTML = totalPrice__value(items).toFixed(2).replace(".", ",") + " €";

    ////////////////////////////
    // Handle form submission //
    ////////////////////////////

    const form = ref.form;
    form.addEventListener("submit", (e) => {
      e.preventDefault();
      //////
      const data = new FormData(e.target);
      //////
      const total = () => {
        let total = 0;
        //////
        for (let item of items) {
          total = total + parseFloat(item.price) * parseFloat(item.quantity);
        }
        return total.toFixed(2);
      };
      const order = () => {
        let order = "";
        //////
        for (let item of items) {
          order += item.title + " × " + item.quantity + ", " + (item.quantity * item.price).toFixed(2) + "€ \n";
        }
        order += "\nGesamt: " + total() + "€";
        //////
        return order;
      };
      data.append("Bestellung", order());

      //////
      fetch(e.target.action, {
        method: form.method,
        body: data,
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) => {
          console.log("Thanks for your submission!");

          /////////////////////////
          // Reveal illustration //
          /////////////////////////

          this.setState({
            submitted: true,
          });

          ////////////////
          // Reset form //
          ////////////////

          form.reset();

          //////////////////////////
          // Set slug of exit-URL //
          //////////////////////////

          this.setState({
            URL_exit__slug: "/",
          });

          ////////////////////////////////////
          // Clear items from local-storage //
          ////////////////////////////////////

          const currentItems = [];
          localStorage.setItem("items", JSON.stringify(currentItems));
          eventbus.emit("change_cart");
        })
        .catch((error) => {
          console.log("Oops! There was a problem submitting your form");
        });
    });

    /////////////////////////////////////
    // Submit-action via remote button //
    /////////////////////////////////////

    const sendOrderButton = ref.sendOrderButton;
    const sendOrderButton_remote = ref.sendOrderButton_remote;
    sendOrderButton_remote.addEventListener("click", () => {
      sendOrderButton.click();
    });

    ////////////////////////////////
    // Config. other interactions //
    ////////////////////////////////

    const closeButton = ref.closeButton;
    closeButton.addEventListener("click", () => {
      if (this.state.submitted) {
        eventbus.emit("close_cart");
      } else {
        eventbus.emit("close_orderForm");
      }
    });
  }

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const refs = this.ref;

    ////////////////////////////////////
    ////////////////////////////////////

    if ("submitted" in changes) {
      const submitted = state.submitted;
      const message_submission = refs.message_submission;
      //////
      if (submitted) {
        message_submission.classList.remove("order-form__submission-message_hidden");
      } else {
        message_submission.classList.add("order-form__submission-message_hidden");
      }
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("URL_exit__slug" in changes) {
      const closeButton = refs.closeButton;
      const closeButton__link = closeButton.querySelector("a");
      //////
      const URL_exit__slug = state.URL_exit__slug;
      const URL_exit__base = closeButton.dataset.urlBase;
      //////
      closeButton__link.href = URL_exit__base + URL_exit__slug;
    }
  }
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

export { orderForm_PROTO };
