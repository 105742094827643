import Component from "gia/Component";
import eventbus from "gia/eventbus";

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

class cartItem extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      quantityValue: null,
      priceValue: null,
      deleteButton: null,
      increaseQuantityButton: null,
      decreaseQuantityButton: null,
    };
    //////
    this.options = {
      slug: "defaultValue",
      price: "defaultValue",
      quantity: "defaultValue",
    };
  }

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    const compEl = this.element;

    const deleteButton = this.ref.deleteButton;
    const increaseQuantityButton = this.ref.increaseQuantityButton;
    const decreaseQuantityButton = this.ref.decreaseQuantityButton;

    const productSlug = this.options.slug;

    const quantity = this.options.quantity;

    ////////////////////////////////////
    ////////////////////////////////////

    this.setState({
      quantity: quantity,
    });

    ////////////////////////////////////
    ////////////////////////////////////

    deleteButton.addEventListener("click", () => {
      ///////////////////////////////////
      // Remove item from localStorage //
      ///////////////////////////////////

      let currentItems = JSON.parse(localStorage.getItem("items"));

      for (let item of currentItems) {
        const itemSlug = item.slug;
        //////
        if (itemSlug === productSlug) {
          const index = currentItems.indexOf(item);
          currentItems.splice(index, 1);
          break;
        }
      }

      localStorage.setItem("items", JSON.stringify(currentItems));
      eventbus.emit("change_cart");

      //////////////////////////
      // Remove item from DOM //
      //////////////////////////

      compEl.remove();
    });

    ////////////////////////////////////
    ////////////////////////////////////

    increaseQuantityButton.addEventListener("click", () => {
      const quantity = parseInt(this.state.quantity);
      const quantity_new = quantity + 1;
      //////
      this.setState({
        quantity: quantity_new,
      });
      //////
      if (quantity_new > 1) {
        decreaseQuantityButton.classList.remove("cart-item__quantity-button_inactive");
      }
    });

    decreaseQuantityButton.addEventListener("click", () => {
      const quantity = parseInt(this.state.quantity);
      const quantity_new = quantity - 1;
      //////
      if (quantity > 1) {
        this.setState({
          quantity: quantity_new,
        });
        //////
        if (quantity_new === 1) {
          decreaseQuantityButton.classList.add("cart-item__quantity-button_inactive");
        }
      }
    });
  }

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(changes) {
    const productSlug = this.options.slug;
    //////
    if ("quantity" in changes) {
      //////////////////////////////////////////////
      // Set new quantity & price in DOM elements //
      //////////////////////////////////////////////

      const quantity = this.state.quantity;
      const quantityValueEl = this.ref.quantityValue;
      quantityValueEl.innerHTML = quantity;
      //////
      const price = this.options.price;
      const priceEl = this.ref.priceValue;
      const newPrice = (quantity * price).toFixed(2).replace(".", ",");
      priceEl.innerHTML = newPrice + " €";

      ///////////////////////////////////////////
      // Set new quantity in localStorage-item //
      ///////////////////////////////////////////

      let currentItems = JSON.parse(localStorage.getItem("items"));
      //////
      for (let item of currentItems) {
        const itemSlug = item.slug;
        //////
        if (itemSlug === productSlug) {
          item.quantity = quantity;
          break;
        }
      }
      //////
      localStorage.setItem("items", JSON.stringify(currentItems));
      eventbus.emit("change_cart");
    }
  }
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

export { cartItem };
