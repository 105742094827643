import Component from "gia/Component";
import eventbus from "gia/eventbus";
import Swiper from "swiper";
import gsap from "gsap";

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

class projectSlider extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      shopImages: [],
      slides: [],
    };
    //////
    this.options = {
      zoom: "defaultValue",
    };
  }
  //////
  mount() {
    const projectSliderEl = this.element;
    const slides = this.ref.slides;

    ////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    const breakpoint = window.matchMedia("(min-width: 768px)");
    let slider;

    ////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    const breakpointChecker = function () {
      if (breakpoint.matches === true) {
        enableSwiper();
      } else {
        if (slider !== undefined) {
          slider.destroy(true, true);
        } else {
          return;
        }
      }
    };

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    const has__zoom = this.options.zoom == "true";
    const enableSwiper = function () {
      ////////////////////////////////////////////////
      // Init. image-zoom on slides w/ zoom enabled //
      ////////////////////////////////////////////////

      if (has__zoom) {
        for (let slide of slides) {
          if (slide.hasAttribute("data-zoom")) {
            const imageWrapper = slide.querySelector(".wrapper_img_zoom");
            imageWrapper.addEventListener("mousemove", (e) => {
              const zoom__target = imageWrapper;
              //////
              const offsetX = e.offsetX;
              const offsetY = e.offsetY;
              //////
              const x = (offsetX / zoom__target.offsetWidth) * 100;
              const y = (offsetY / zoom__target.offsetHeight) * 100;
              //////
              zoom__target.style.backgroundPosition = x + "% " + y + "%";
            });
          }
        }
      }

      //////////////////
      // init. slider //
      //////////////////

      slider = new Swiper(projectSliderEl, {
        allowTouchMove: false,
        on: {
          slideChange: () => {
            const currentSlide = slider.slides[slider.realIndex];

            /////////////////
            // set caption //
            /////////////////

            const caption = currentSlide.dataset.caption;
            const args = { caption: caption };
            eventbus.emit("update_caption", args);
            eventbus.emit("projectSlider_slideChange", args);
          },
          slidePrevTransitionStart: () => {
            eventbus.emit("projectSlider_prevSlide");
          },
          slideNextTransitionStart: function () {
            eventbus.emit("projectSlider_nextSlide");
          },
          reachEnd: function () {
            eventbus.emit("projectSlider_reachedEnd");
          },
          reachBeginning: function () {
            eventbus.emit("projectSlider_reachedBeginning");
          },
        },
      });
      //////
      eventbus.on("sliderBtn_click", (args) => {
        if (args.direction === "prev") {
          slider.slidePrev();
        } else {
          slider.slideNext();
        }
      });
    };

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    breakpointChecker();
  }
  unmount() {
    eventbus.off("sliderBtn_click");
  }
  //////
}
//////
class projectSlideCounter extends Component {
  constructor(element) {
    super(element);
    //
    this.ref = {
      currentCount: null,
    };
    //
    this.lstnr_call_prevSlide = this.hdl_call_prevSlide.bind(this);
    this.lstnr_call_nextSlide = this.hdl_call_nextSlide.bind(this);
  }
  //
  mount() {
    this.setState({
      currentCount: 1,
    });
    //
    eventbus.on("projectSlider_prevSlide", this.lstnr_call_prevSlide);
    eventbus.on("projectSlider_nextSlide", this.lstnr_call_nextSlide);
  }
  //
  unmount() {
    eventbus.off("projectSlider_prevSlide", this.lstnr_call_prevSlide);
    eventbus.off("projectSlider_nextSlide", this.lstnr_call_nextSlide);
  }
  //
  hdl_call_prevSlide() {
    const newCurrentCount = this.state.currentCount - 1;
    //
    this.setState({
      currentCount: newCurrentCount,
    });
  }
  hdl_call_nextSlide() {
    const newCurrentCount = this.state.currentCount + 1;
    //
    this.setState({
      currentCount: newCurrentCount,
    });
  }
  //
  stateChange(stateChanges) {
    const currentCount = stateChanges.currentCount;
    const currentCountEl = this.ref.currentCount;
    //
    currentCountEl.innerHTML = currentCount;
  }
}
//////
class sliderBtn extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      arrow: null,
    };
    //////
    this.options = {
      direction: "defaultValue",
      linkTarget: "defaultValue",
      reached_end: "defaultValue",
    };
    //////
    this.lstnr_call_prevSlide = this.hdl_call_prevSlide.bind(this);
    this.lstnr_call_nextSlide = this.hdl_call_nextSlide.bind(this);
    this.lstnr_call_reachedBeginning = this.hdl_call_reachedBeginning.bind(this);
    this.lstnr_call_reachedEnd = this.hdl_call_reachedEnd.bind(this);
  }
  //////
  mount() {
    const compEl = this.element;
    const arrow = this.ref.arrow;
    const direction = this.options.direction;
    const linkTarget = this.options.linkTarget;

    const width_window = window.innerWidth;
    const width_compEl = compEl.offsetWidth;
    const width_arrow = arrow.offsetWidth;

    const height_window = window.innerHeight;
    const height_compEl = compEl.offsetHeight;
    const height_arrow = arrow.offsetHeight;

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    this.setState({
      initialised: false,
      reachedEnd: this.options.reached_end,
      reachedBeginning: true,
    });

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    if (window.innerWidth >= 768) {
      compEl.addEventListener("click", () => {
        let args = { direction: direction };
        const reachedEnd = this.state.reachedEnd;
        const reachedBeginning = this.state.reachedBeginning;
        //////
        if (reachedBeginning === false && reachedEnd === false) {
          eventbus.emit("sliderBtn_click", args);
        } else {
          if (reachedBeginning === true && reachedEnd === true) {
            args = { linkTarget: linkTarget };
            eventbus.emit("swup_navigateToPage", args);
          } else {
            if (reachedBeginning === true) {
              if (direction === "prev") {
                args = { linkTarget: linkTarget };
                eventbus.emit("swup_navigateToPage", args);
              } else {
                eventbus.emit("sliderBtn_click", args);
              }
            } else {
              if (direction === "next") {
                args = { linkTarget: linkTarget };
                eventbus.emit("swup_navigateToPage", args);
              } else {
                eventbus.emit("sliderBtn_click", args);
              }
            }
          }
        }
      });
      compEl.addEventListener("mouseenter", () => {
        compEl.classList.add("active");
      });
      compEl.addEventListener("mouseleave", () => {
        compEl.classList.remove("active");
      });
      compEl.addEventListener("mousemove", (e) => {
        const mouseX = e.clientX;
        const mouseY = e.clientY;
        const mouseY_relativeToComp = mouseY - (height_window - height_compEl);
        let mouseX_relativeToComp;

        const isInitialised = this.state.initialised;
        //////
        if (isInitialised === false) {
          this.setState({
            initialised: true,
          });
        }
        //////
        if (direction === "next") {
          mouseX_relativeToComp = mouseX - (width_window - width_compEl);
        } else {
          mouseX_relativeToComp = mouseX;
        }
        //////
        gsap.to(arrow, {
          duration: 0,
          x: mouseX_relativeToComp - width_arrow / 2,
          y: mouseY_relativeToComp - height_arrow / 2,
        });
      });

      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////

      eventbus.on("projectSlider_prevSlide", this.lstnr_call_prevSlide);
      eventbus.on("projectSlider_nextSlide", this.lstnr_call_nextSlide);
      eventbus.on("projectSlider_reachedEnd", this.lstnr_call_reachedEnd);
      eventbus.on("projectSlider_reachedBeginning", this.lstnr_call_reachedBeginning);
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  unmount() {
    if (window.innerWidth >= 768) {
      eventbus.off("projectSlider_prevSlide", this.lstnr_call_prevSlide);
      eventbus.off("projectSlider_nextSlide", this.lstnr_call_nextSlide);
      eventbus.off("projectSlider_reachedEnd", this.lstnr_call_reachedEnd);
      eventbus.off("projectSlider_reachedBeginning", this.lstnr_call_reachedBeginning);
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  stateChange(changes) {
    if ("initialised" in changes) {
      const compEl = this.element;
      const isInitialised = this.state.initialised;
      if (isInitialised === true) {
        compEl.classList.remove("uninitialised");
      }
    }

    /////////////////////////////////
    /////////////////////////////////

    const direction = this.options.direction;

    if ("reachedEnd" in changes) {
      if (direction === "next") {
        const arrow = this.ref.arrow;
        const hasReachedEnd = this.state.reachedEnd;
        //////
        if (hasReachedEnd === true) {
          arrow.classList.add("arrow_show_nextProject");
        } else {
          arrow.classList.remove("arrow_show_nextProject");
        }
      }
    }

    if ("reachedBeginning" in changes) {
      if (direction === "prev") {
        const arrow = this.ref.arrow;
        const hasReachedBeginning = this.state.reachedBeginning;
        //////
        if (hasReachedBeginning === true) {
          arrow.classList.add("arrow_show_nextProject");
        } else {
          arrow.classList.remove("arrow_show_nextProject");
        }
      }
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  hdl_call_prevSlide() {
    this.setState({
      reachedEnd: false,
    });
  }
  hdl_call_nextSlide() {
    this.setState({
      reachedBeginning: false,
    });
  }
  hdl_call_reachedBeginning() {
    this.setState({
      reachedBeginning: true,
    });
  }
  hdl_call_reachedEnd() {
    this.setState({
      reachedEnd: true,
    });
  }
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class caption extends Component {
  constructor(element) {
    super(element);
    //
    this.lstnr_call_updateCaption = this.hdl_call_updateCaption.bind(this);
  }
  //
  mount() {
    eventbus.on("update_caption", this.lstnr_call_updateCaption);
  }
  unmount() {
    eventbus.off("update_caption", this.lstnr_call_updateCaption);
  }
  //
  hdl_call_updateCaption(args) {
    this.element.innerHTML = args.caption;
  }
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

const components_projectSlider = {
  projectSlider: projectSlider,
  projectSlideCounter: projectSlideCounter,
  sliderBtn: sliderBtn,
  caption: caption,
};

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export default components_projectSlider;
