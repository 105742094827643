import Component from "gia/Component";
import eventbus from "gia/eventbus";
import Swiper, { Navigation } from "swiper";
import gsap from "gsap";
//////
Swiper.use(Navigation);
//////
class aboutSlider extends Component {
  constructor(element) {
    super(element);
  }
  //
  mount() {
    const aboutSliderEL = this.element;

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    const breakpoint = window.matchMedia("(min-width: 768px)");
    let slider;

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    const breakpointChecker = function () {
      if (breakpoint.matches === true) {
        enableSwiper();
      } else {
        if (slider !== undefined) {
          slider.destroy(true, true);
        } else {
          return;
        }
      }
    };

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    const enableSwiper = function () {
      slider = new Swiper(aboutSliderEL, {
        allowTouchMove: false,
        slidesPerView: "auto",
        initialSlide: 0,
        //////
        on: {
          slidePrevTransitionStart: () => {
            eventbus.emit("aboutSlider_prevSlide");
          },
          slideNextTransitionStart: function () {
            eventbus.emit("aboutSlider_nextSlide");
          },
          reachEnd: function () {
            eventbus.emit("aboutSlider_reachedEnd");
          },
          reachBeginning: function () {
            eventbus.emit("aboutSlider_reachedBeginning");
          },
        },
      });

      eventbus.on("aboutSliderBtn_click", (args) => {
        if (args.direction === "prev") {
          slider.slidePrev();
          slider.slidePrev();
        } else {
          slider.slideNext();
          slider.slideNext();
        }
      });
    };

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    breakpointChecker();
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  unmount() {}
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class aboutSliderBtn extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      arrow: null,
    };
    //////
    this.options = {
      direction: "defaultValue",
    };
    //////
    this.lstnr_call_prevSlide = this.hdl_call_prevSlide.bind(this);
    this.lstnr_call_nextSlide = this.hdl_call_nextSlide.bind(this);
    this.lstnr_call_reachedBeginning = this.hdl_call_reachedBeginning.bind(this);
    this.lstnr_call_reachedEnd = this.hdl_call_reachedEnd.bind(this);
  }
  mount() {
    const compEl = this.element;
    const arrow = this.ref.arrow;
    const direction = this.options.direction;

    const width_window = window.innerWidth;
    const width_compEl = compEl.offsetWidth;
    const width_arrow = arrow.offsetWidth;

    const height_window = window.innerHeight;
    const height_compEl = compEl.offsetHeight;
    const height_arrow = arrow.offsetHeight;

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    this.setState({
      initialised: false,
      reachedEnd: false,
      reachedBeginning: true,
    });

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    if (window.innerWidth >= 768) {
      compEl.addEventListener("click", () => {
        const args = { direction: direction };
        eventbus.emit("aboutSliderBtn_click", args);
      });
      compEl.addEventListener("mouseenter", () => {
        compEl.classList.add("active");
      });
      compEl.addEventListener("mouseleave", () => {
        compEl.classList.remove("active");
      });
      compEl.addEventListener("mousemove", (e) => {
        const mouseX = e.clientX;
        const mouseY = e.clientY;
        const mouseY_relativeToComp = mouseY - (height_window - height_compEl);
        let mouseX_relativeToComp;

        const isInitialised = this.state.initialised;
        //////
        if (isInitialised === false) {
          this.setState({
            initialised: true,
          });
        }
        //////
        if (direction === "next") {
          mouseX_relativeToComp = mouseX - (width_window - width_compEl);
        } else {
          mouseX_relativeToComp = mouseX;
        }
        //////
        gsap.to(arrow, {
          duration: 0,
          x: mouseX_relativeToComp - width_arrow / 2,
          y: mouseY_relativeToComp - height_arrow / 2,
        });
      });

      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////

      eventbus.on("aboutSlider_prevSlide", this.lstnr_call_prevSlide);
      eventbus.on("aboutSlider_nextSlide", this.lstnr_call_nextSlide);
      eventbus.on("aboutSlider_reachedEnd", this.lstnr_call_reachedEnd);
      eventbus.on("aboutSlider_reachedBeginning", this.lstnr_call_reachedBeginning);
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  unmount() {
    if (window.innerWidth >= 768) {
      eventbus.off("aboutSlider_prevSlide", this.lstnr_call_prevSlide);
      eventbus.off("aboutSlider_nextSlide", this.lstnr_call_nextSlide);
      eventbus.off("aboutSlider_reachedEnd", this.lstnr_call_reachedEnd);
      eventbus.off("aboutSlider_reachedBeginning", this.lstnr_call_reachedBeginning);
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  stateChange(changes) {
    if ("initialised" in changes) {
      const compEl = this.element;
      const isInitialised = this.state.initialised;
      if (isInitialised === true) {
        compEl.classList.remove("uninitialised");
      }
    }

    /////////////////////////////////
    /////////////////////////////////

    const direction = this.options.direction;

    if ("reachedEnd" in changes) {
      console.log(changes);
      if (direction === "next") {
        const compEl = this.element;
        const hasReachedEnd = this.state.reachedEnd;
        //////
        if (hasReachedEnd === true) {
          compEl.classList.add("wrapper_btn_PROTO_disabled");
        } else {
          compEl.classList.remove("wrapper_btn_PROTO_disabled");
        }
      }
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  hdl_call_prevSlide() {
    this.setState({
      reachedEnd: false,
    });
  }
  hdl_call_nextSlide() {
    this.setState({
      reachedBeginning: false,
    });
  }
  hdl_call_reachedBeginning() {
    this.setState({
      reachedBeginning: true,
    });
  }
  hdl_call_reachedEnd() {
    this.setState({
      reachedEnd: true,
    });
  }
}
//////
const components_aboutSlider = {
  aboutSlider: aboutSlider,
  aboutSliderBtn: aboutSliderBtn,
};
//////
export default components_aboutSlider;
