import Swup from "swup";
import SwupFadeTheme from "@swup/fade-theme";
import SwupPreloadPlugin from "@swup/preload-plugin";
import SwupGiaPlugin from "@swup/gia-plugin";
//////
import loadComponents from "gia/loadComponents";
import eventbus from "gia/eventbus";
//////
import lozad from "lozad";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

// --------------- GIA --------------- //

import components_nav from "./components/nav";
import components_projectSlider from "./components/projectSlider";
import components_projectVideoPlayer from "./components/projectVideoPlayer";
import components_aboutSlider from "./components/aboutSlider";
import components_backToTopBtn from "./components/backToTopBtn";

import { productInfo } from "../components/product-info/product-info";
import { orderForm } from "../components/order-form/order-form";
import { orderForm_PROTO } from "../components/order-form/order-form_PROTO";
import { cart } from "../components/cart/cart";
import { cartButton } from "../components/cart-button/cartButton";

const siteComponents = {
  ...components_projectSlider,
  ...components_projectVideoPlayer,
  ...components_aboutSlider,
  ...components_backToTopBtn,
  productInfo,
  orderForm,
  orderForm_PROTO,
  cart,
};

/////////////////////////////////
/////////////////////////////////

// ----- GLOBAL COMP. ----- //

loadComponents({ cartButton: cartButton });
loadComponents({ nav: components_nav.nav }, document.querySelector(".wrapper_comp_nav.top"));
loadComponents({ bottomNav: components_nav.bottomNav }, document.querySelector(".wrapper_comp_nav.bottom"));

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

// --------------- SWUP --------------- //

function initPage() {
  swup.preloadPage("/");
  //////
  const context_homepageGrid = document.getElementById("homepageGrid");
  //////
  if (context_homepageGrid) {
    eventbus.emit("swup_entered_homepage");
    // ----- LOZAD ----- //
    const observer = lozad();
    observer.observe();
  }
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

const swup = new Swup({
  plugins: [new SwupFadeTheme(), new SwupPreloadPlugin(), new SwupGiaPlugin({ components: siteComponents, log: true })],
  animateHistoryBrowsing: true,
});

/////////////////////////////////
/////////////////////////////////

swup.on("contentReplaced", initPage);
swup.on("clickLink", (event) => {
  const target = event.delegateTarget.dataset.target;
  //////
  if (target === "project") {
    eventbus.emit("swup_leaving_homepage");
  }
  //////
  if (target === "order") {
    const productName = event.delegateTarget.dataset.productName;
    console.log(productName);
    //////
    const args = { productName: productName };
    eventbus.emit("set_orderForm", args);
  }
});

/////////////////////////////////
/////////////////////////////////

eventbus.on("swup_navigateToPage", (args) => {
  swup.loadPage({
    url: args.linkTarget,
  });
});

eventbus.on("open_cart", () => {
  swup.loadPage({
    url: "/" + document.body.dataset.lang + "/shop/cart",
  });
});

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

initPage();

/////////////////////////////////
/////////////////////////////////

///////////////////////////
// Setting shopping cart //
///////////////////////////

let cartItems = JSON.parse(localStorage.getItem("items"));

if (cartItems == null) {
  cartItems = [];
  localStorage.setItem("items", JSON.stringify(cartItems));
}
