import Component from "gia/Component";
import eventbus from "gia/eventbus";

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

class cartButton extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      counter: null,
    };
  }

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    const compEl = this.element;

    /////////////////////////////
    // Setting cart item count //
    /////////////////////////////

    let currentItems = JSON.parse(localStorage.getItem("items"));
    let totalQuantity = 0;
    for (let item of currentItems) {
      const quantity = parseInt(item.quantity);
      totalQuantity += quantity;
    }

    if (currentItems == null) {
      currentItems = [];
      localStorage.setItem("items", JSON.stringify(currentItems));
    }

    this.setState({
      count: totalQuantity,
    });

    ////////////////////////////////////
    ////////////////////////////////////

    compEl.addEventListener("click", () => {
      eventbus.emit("open_cart");
      //////
      this.setState({
        hidden: true,
      });
    });

    ////////////////////////////////////
    ////////////////////////////////////

    eventbus.on("open_shop", () => {
      this.setState({
        hidden: false,
      });
    });

    eventbus.on("close_shop", () => {
      this.setState({
        hidden: true,
      });
    });

    eventbus.on("close_cart", () => {
      this.setState({
        hidden: false,
      });
    });

    eventbus.on("open/close_nav", () => {
      if (
        document.getElementById("swup").classList.contains("shop") ||
        document.getElementById("swup").classList.contains("product")
      ) {
        this.setState({
          hidden: this.state.hidden ? false : true,
        });
      }
    });

    ////////////////////////////////////
    ////////////////////////////////////

    eventbus.on("add_cartItem", () => {
      const currentCount = this.state.count;
      //////
      this.setState({
        count: currentCount + 1,
      });
    });

    eventbus.on("change_cart", () => {
      /////////////////////////////////
      // Count total amount of items //
      /////////////////////////////////

      let currentItems = JSON.parse(localStorage.getItem("items"));
      let totalQuantity = 0;

      for (let item of currentItems) {
        const quantity = parseInt(item.quantity);
        totalQuantity += quantity;
      }

      this.setState({
        count: totalQuantity,
      });
    });
  }

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(changes) {
    if ("hidden" in changes) {
      const compEl = this.element;
      const isHidden = this.state.hidden;
      //////
      if (isHidden === true) {
        compEl.classList.remove("cart-button_active");
        compEl.classList.add("cart-button_hidden");
      } else {
        compEl.classList.remove("cart-button_hidden");
        compEl.classList.add("cart-button_active");
      }
    }

    /////////////////////////////////
    /////////////////////////////////

    if ("count" in changes) {
      const counter = this.ref.counter;
      const currentCount = this.state.count;
      //////
      counter.innerHTML = currentCount;
    }
  }
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

export { cartButton };
